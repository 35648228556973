<template>
  <div id="registration-page">

    <MemberEditorCompactModal 
      ref="member-editor-modal"
      :member_id="member_id"
      @created="empty"
      @updated="empty"
      @deleted="empty"
    />
    
    <RegistrationTable
      class="mb-32"
      @skv_job_finished="skv_registration_job_finished"
      @member_clicked="member_clicked"
    />

    <MemberJobsFileTable 
      :files="files"
    />
  </div>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

import RegistrationTable from '@/view/pages/ml/faith/member_jobs/RegistrationTable.vue';
import MemberJobsFileTable from '@/view/pages/ml/faith/member_jobs/MemberJobsFileTable.vue';


export default {
  name: 'registrations-page',
  mixins: [ toasts ],
  components: {
    RegistrationTable,
    MemberJobsFileTable,
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings', 'periods']),
    

  },
  mounted() {
    this.load_skv_files();
  },
  data() {
    return {
      
      files: [],
      member_id: null
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
      this.load_skv_files();
    }

  },
  methods: {
    empty(){},

    member_clicked(member_id) {
      this.member_id = member_id;
      this.$refs['member-editor-modal'].show(member_id);
    },

    skv_registration_job_finished(job) {
      this.files.unshift(job.file);

      this.files = [...this.files]
    },

    

    async load_skv_files() {
      try {
        const res = await axios.get(`/skv/files/${this.currentPeriodId}`);

        if (res.status !== 200) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_JOBS.UNABLE_LOAD'));
          return;  
        }

        this.files = res.data.filter(item => item.type === 'SKV_EXPORT_REGISTRATIONS');
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_JOBS.UNABLE_LOAD'));
      }
    }
  }
};
</script>
