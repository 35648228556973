<template>
  <div>
    <div class="d-flex justify-content-end mb-4">

      <div class="card-toolbar">

        <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click.prevent="run_skv_job"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('COMPANY_PERIOD.CREATE_CORRECTIONS_FILE') }} </a
        >
      </div>
    </div>


    <b-table
      id="member-registrations-table"
      class="table-striped mh-100"
      :fields="headers"
      :items="items"
      head-variant="light"
      sticky-header
      responsive
      striped
      hover
    >

      <template #cell(actions)="row">
        <div class="justify-content-end d-flex">
          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="select_member_clicked(row.item.member_id)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-user color-primary"></i>
            </span>
          </a>
        </div>
      </template>


    </b-table>

    <div v-if="items.length === 0">
      {{ $t('COMPANY_PERIOD.NO_ROWS_FOUND') }}
    </div>

  </div>

</template>
<style lang="scss" scoped>
.pagination-select {
  height: 32px;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-right: 12px;
  max-width: 100px;
}

:deep .td-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 10%;
}


</style>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';


export default {
  name: "MemberCorrectionsTable",
  mixins: [ toasts ],
  props: ["items","show_create"],
  emits: ['member_clicked','skv_job_finished'],
  components: {

  },
  watch: {

  },
  computed:{
    ...mapGetters(['isTHS','currentCompanyId','currentPeriodId']),
  },
  mounted() {
    if (this.isTHS) {
      // do something as THS admin?
    }
  },
  methods: {

    async run_skv_job() {
      try {

        const loader = this.$loading.show();

        const res = await axios.post(`/skv/export/member/corrections`, {
          skv_period_id: this.currentPeriodId
        });

        loader && loader.hide();

        if (res.status !== 201) {

          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_JOBS.ERROR_RUNNING_JOB'));

          return;
        }

        downloadWithAxios(get_base_url() + `/dlfile/${res.data.file.linkstr}`, res.data.file.name);

        this.$emit('skv_job_finished', res.data);
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.MEMBER_JOBS.ERROR_RUNNING_JOB'));
      }

    },

    select_member_clicked(member_id) {
      this.$emit('member_clicked', member_id);
    },


  },
  data() {
    return {
      newRowValue: "",
      filters: {
        text: ''
      },
      total_rows: 0,
      per_page: 100,
      current_page: 1,
      per_page_options: [
        100, 250, 500
      ],
      headers: [

        {
          key: 'public_id',
          label: this.$t('MEMBER.PUBLIC_ID'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'firstname',
          label: this.$t('MEMBER.FIRSTNAME'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle td-overflow'
        },

        {
          key: 'lastname',
          label: this.$t('MEMBER.LASTNAME'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle td-overflow'
        },

        {
          key: 'fmt_personnr',
          label: this.$t('MEMBER.PERSONNR'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },

        {
          key: 'created_at',
          label: this.$t('PAGES.MEMBER_JOBS.ACCEPTED_AT'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle td-overflow'
        },
        {
          key: 'skv_period_id',
          label: this.$t('SKV.SKV_PERIOD'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle',
          formatter: (_, __, item) => {
            return item.skv_period ? item.skv_period.name : '-';
          }
        },
        {
          key: 'actions',
          label: ' ',
          thClass: 'w-120px text-right pr-12 align-middle',
        },

      ],

    };
  }
};
</script>
